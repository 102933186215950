<div *ngFor="let appointment of dailyAppointments">
  <div class="appointment-container" *ngIf="!loading" [style.top.rem]="appointment.appointmentTopPosition" (click)="showAppointment(appointment.appointmentId)">
    <div class="appointment" [ngClass]="appointment.statusStyle" [style.height.rem]="appointment.appointmentHeight" [style.width.%]="appointment.appointmentWidth" [style.left.%]="appointment.appointmentLeft">
      <div *ngIf="!isWeekView" class="appointment-time-container col-auto">
        <div class="appointment-time" [ngClass]="appointment.timeStyle">
          <span style="width: 100%">{{ appointment.startTime.format('HH:mm') }}</span>
        </div>
      </div>
      <div class="col appointment-info-spaced" [ngClass]="{'less-spaced':isWeekView}">
        <span class="appointment-vrm">{{ appointment.vrm }}</span>
        <span *ngIf="!isWeekView" class="appointment-status">{{ appointment.status }}</span>
        <span *ngIf="isBranchView" class="appointment-buyer">{{ appointment.assignedTo }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="lunchTopPosition" class="appointment-container" [style.top.rem]="lunchTopPosition" [style.left.%]="lunchSequence" [style.width.%]="lunchWidth">
  <div class="appointment-lunch" [style.height.rem]="lunchHeight">
    <span>LUNCH</span>
  </div>
</div>

<div *ngIf="loading" style="flex: auto;">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</div>