<div class="container-fluid main-page">
  <div class="row content">
    <div class="col-2 replace-padding" *ngIf="!isPostPurchase && isPurchaseMenuVisible">
      <div class="reg-plate">
          <app-registration-plate [vrm]="requestContext" [isPurchaseMenu] ="true"></app-registration-plate>
      </div>
      <div class="row">
        <app-purchase-menu [quoteStateId]="quoteStateId" [currentState]="stateName" [vrm]="requestContext" [canGoBack]="!isPurchaseFinalised"
        (stateReturn)="onGoBackInState($event)"></app-purchase-menu>
      </div>
      
    </div>
    <div class="mt-3 page-container" [ngClass]="isPostPurchase || !isPurchaseMenuVisible ? 'col-12' : 'col-10'">

      <div class="page-title sticky-top" [hidden]="!doShowStickyElements">

        <h2 class="page-header">{{ title }}</h2>

        <div [hidden]="!showNavigationButtons()" class="buttons">
          <button [hidden]="!appointmentValuationDetails" #appointmentDetailsButton type="button"
            class="appointment-details-btn nav-button me-2">
            Appointment Details
            <img class="receipt-img" src="../../assets/icons/buttons/recipt.png" alt="Receipt"/>
          </button>
          <button type="button" class="btn nav-button direction me-1" [disabled]="backButtonDisabled" (click)="onBackButton()">
            <img src="../../assets/icons/buttons/arrowBack.png" class="m-1" alt="Back">
            Back
          </button>
          <button #saveButton type="button" class="btn nav-button next direction me-2 save-button" [ngClass]="isFinishedLoading && isBusy ? 'loading' : ''"
            [disabled]="saveButtonDisabled">
            Next
            <img src="../../assets/icons/buttons/arrowNext.png" class="m-1" alt="Next">
          </button>
        </div>

        <div ngbDropdown [hidden]="!showNavigationButtons()" class="dropdown popout">
          <button ngbDropdownToggle id="dropdownMenuButton" class="popout-btn">
            <img src="../../assets/icons/buttons/popout.png" alt="Popout">
          </button>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <button ngbDropdownItem #suspendButton type="button" class="float-btn popout-btn" [hidden]="!canEndPurchase"
              [ngClass]="isFinishedLoading && isBusy ? 'loading' : ''">
              <img src="../../assets/icons/buttons/suspend.png" alt="Suspend">
              Suspend
            </button>
            <button ngbDropdownItem #abortButton type="button" class="float-btn popout-btn quit-btn" [ngClass]="canEndPurchase ? 'not-top-btn' : ''" [disabled]="quitButtonDisabled">
              <img
                [src]="quitButtonDisabled ? '../../assets/icons/buttons/quit-disabled.png' : '../../assets/icons/buttons/quit.png'"
                alt="Not Purchased">
              Not Purchased
            </button>

            <button ngbDropdownItem [hidden]="stateName !== 'Start Purchase'" #notMyCarButton type="button"
              class="float-btn popout-btn not-top-btn">
              <img src="../../assets/icons/buttons/change_vrm.svg" alt="Not my car">
              Not My Car
            </button>
          </div>
        </div>
      </div>
      <div class="page-content">
        <div *ngIf="isFinishedLoading">
          <div [formGroup]="questionForm" [ngClass]="{'valuation-state container': stateName === 'Your Offer', 'display-questions' : stateName === 'Other Questions'}">
            <div [ngClass]="isSingleQuestion() ? '' : 'question-group'" *ngFor="let group of groupedQuestions; let groupIndex = index;">
              <ng-container formArrayName="items" *ngFor="let item of questionForm.get('items')['controls']; let i = index;">
                <div *ngIf="isQuestionInGroup(group, item) && item.controls.controlType.value != 0 && showItem(item)"
                    [ngClass]="isLinkedToPreviousLine(i) ? 'container' : 'question-container container'" [formGroupName]="i">
                  <ng-container [ngSwitch]="questionForm.value.items[i].controlType">

                    <h4 *ngIf="questionForm.value.items[i].heading">{{ questionForm.value.items[i].heading }}</h4>

                    <app-text-input *ngSwitchCase="controlTypes.TEXT_ENTRY" [group]="item" [isInline]="group.length > 1"
                      (change)="onAnswerChange($event)" (keyup.enter)="confirmEntry($event)">
                    </app-text-input>
                    <app-number-input *ngSwitchCase="controlTypes.NUMBER_ENTRY" [group]="item" [isInline]="group.length > 1"
                      (change)="onAnswerChange($event)" (keyup.enter)="confirmEntry($event)">
                    </app-number-input>
                    <app-postcode-lookup-input *ngSwitchCase="controlTypes.ADDRESS_LOOKUP" [group]="item" [questionForm]="questionForm"
                      (change)="onAnswerChange($event)">
                    </app-postcode-lookup-input>
                    <app-vat-check-input *ngSwitchCase="controlTypes.VAT_LOOKUP" [group]="item" [questionForm]="questionForm" [quoteStateId]="quoteStateId"
                      (change)="onAnswerChange($event)">
                    </app-vat-check-input>
                    <div *ngSwitchCase="controlTypes.DATE_ENTRY">
                      <app-date-input [group]="item" (change)="onAnswerChange($event)"
                        (keyup.enter)="confirmEntry($event)" [isInline]="group.length > 1">
                      </app-date-input>
                    </div>
                    <app-list-input *ngSwitchCase="controlTypes.INLINE_LIST_RADIO" [group]="item"
                      [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
                    </app-list-input>
                    <app-list-input *ngSwitchCase="controlTypes.INLINE_BOOL_RADIO" [group]="item"
                      [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
                    </app-list-input>
                    <app-radio-input *ngSwitchCase="controlTypes.LIST_RADIO" [vertical]="true" [group]="item"
                      [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
                    </app-radio-input>
                    <app-radio-input *ngSwitchCase="controlTypes.BOOL_RADIO" [vertical]="true" [group]="item"
                      [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
                    </app-radio-input>
                    <app-searchable-dropdown-input *ngSwitchCase="controlTypes.SEARCHABLE_DROPDOWN" [group]="item"
                      [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)"
                      (keyup.enter)="confirmEntry($event)" [isInline]="group.length > 1">
                    </app-searchable-dropdown-input>
                    <app-dropdown-input *ngSwitchCase="controlTypes.LIST_DROPDOWN" [group]="item"
                      [options]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)"
                      [isInline]="group.length > 1">
                    </app-dropdown-input>
                    <app-vin-input *ngSwitchCase="controlTypes.VIN_ENTRY" [group]="item" [isInline]="group.length > 1"
                      (change)="onAnswerChange($event)" (keyup.enter)="confirmEntry($event)" [questionForm]="questionForm">
                    </app-vin-input>
                    <ng-container *ngSwitchCase="controlTypes.VALUATION">
                      <app-valuation class="w-100" [group]="item" [quoteStateId]="quoteStateId" [isAppraisalFinalised]="isAppraisalFinalised">
                      </app-valuation>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.HPICHECK">
                      <app-vehicle-check [quoteStateId]="quoteStateId" [vrm]="requestContext" [vehicleDetails]="vehicleDetails" [mileage]="mileage" [ancestryCategory]="ancestryCategory" [group]="item"
                        [questionForm]="questionForm" [writeOffOptions]="getOptions(questionForm.value.items[i].name)" (change)="onAnswerChange($event)">
                      </app-vehicle-check>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.DAMAGE">
                      <app-damage-total [quoteStateId]="quoteStateId" [group]="item" [questionForm]="questionForm" [answers]="updatedAnswers">
                      </app-damage-total>
                    </ng-container>
                    <ng-container *ngSwitchCase="controlTypes.DAMAGE_COSTS">
                      <app-damage-cost [quoteStateId]="quoteStateId" [questionForm]="questionForm" [group]="item" (change)="onAnswerChange($event)">
                      </app-damage-cost>
                    </ng-container>
                    <ng-container *ngSwitchCase="controlTypes.APPRAISAL_REPORT">
                      <app-appraisal-report [quoteStateId]="quoteStateId" [group]="item" [canLoadContent]="answersSaved">
                      </app-appraisal-report>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.MILEAGECHECK">
                      <app-mileage [quoteStateId]="quoteStateId" [vrm]="requestContext" [group]="item" [questionForm]="questionForm" [mileage]="mileage" [page]="questionPages[currentPage]">
                      </app-mileage>
                    </ng-container>
                    <ng-container *ngSwitchCase="controlTypes.VEHICLE_IDENTIFICATION">
                      <div class="vehicle-identification">
                        <div class="capChangeWarning" *ngIf="capIdChangedOnStart">The model identified by the customer does not match the model returned by HPI. The HPI model will be used from now on.</div>
                        <app-vehicle-image [vehicleImage]='vehicleDetails["imageUrl"]' [defaultVehicleImage]='defaultVehicleImage' 
                        [isStartPurchase]='isStartPurchase'>
                        </app-vehicle-image>
                        <div class="align-plates">
                          <app-registration-plate [isStartPurchase]='isStartPurchase' [vrm]='requestContext'></app-registration-plate>
                          <div *ngIf="(isChangeVrmFlow || isAltVRMFlow)"><img src="../../assets/right-arrow.svg" class="m-5"></div>
                          <app-registration-plate *ngIf="(isChangeVrmFlow || isAltVRMFlow)" [isStartPurchase]='isStartPurchase' [vrm]='changeVrm'></app-registration-plate>
                        </div>
                        <app-vehicle-details [questionForm]='item' [mileage]='mileage' [quoteStateId]="quoteStateId" [vrm]="requestContext"
                                             [vehicleDetails]='vehicleDetails' [hideDetails]='false' [HPIFirstRegistered]="HPIFirstRegistered">
                        </app-vehicle-details>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.SERVICE_HISTORY_WARRANTY_CHECK">
                      <app-service-history-warranty-check [mileage]="mileage" [quoteStateId]="quoteStateId"
                        [vrm]="requestContext" [group]="item" [questionForm]='questionForm'>
                      </app-service-history-warranty-check>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.SERVICE_HISTORY_SERVICE_INTERVAL_CHECK">
                      <app-service-interval-check [save]="saveNotifier" [mileage]="mileage" [quoteStateId]="quoteStateId"
                        [vrm]="requestContext" [group]="item">
                      </app-service-interval-check>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.SERVICE_HISTORY_FINAL_STATE">
                      <app-display-service-history-state [group]="item" [quoteStateId]="quoteStateId">
                      </app-display-service-history-state>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.OPTIONAL_EXTRAS">
                      <app-optional-extras [group]="item" [stateName]="stateName" [quoteStateId]="quoteStateId" [questionForm]='questionForm'>
                      </app-optional-extras>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.BANK_VERIFICATION_SERVICE">
                      <app-bank-verification-service [group]="item" [questionForm]='questionForm' [quoteStateId]="quoteStateId" [answers]="updatedAnswers"
                        >
                      </app-bank-verification-service>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.BANK_DETAILS_LOOKUP_TABLE">
                      <app-bank-verification [group]="item" [questionForm]='questionForm' [questionPages]="questionPages" [quoteStateId]="quoteStateId"
                        (onPreviousPage)="onPreviousPage()">
                      </app-bank-verification>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.IMAGE_UPLOAD_PAGE">
                      <app-image-input [save]="saveNotifier" [parameterName] ="null" [group]= "item" [stateName]="stateName" [quoteStateId]="quoteStateId"[questionForm]='questionForm'
                        [label]="" [displayImages]="displayImages" [hasManualImageInput]="false" [requiredImageType]="requiredImageType" [imageData]="questionForm.value.items[i].images">
                      </app-image-input>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.VIDEO_OF_VEHICLE">
                      <app-video-input [save]="saveNotifier" [group]="item" [quoteStateId]="quoteStateId">
                      </app-video-input>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.OUTSTANDING_FINANCE">
                      <app-outstanding-finance [vrm]="requestContext" [group]="item" [questionForm]='questionForm' [quoteStateId]="quoteStateId">
                      </app-outstanding-finance>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.CUSTOMER_GUARANTEES">
                      <app-customer-guarantees [group]="item">
                      </app-customer-guarantees>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.PAYMENT_OPTIONS">
                      <app-payment-options [group]="item" [quoteStateId]="quoteStateId" [questionForm]='questionForm' [canLoadContent]="answersSaved">
                      </app-payment-options>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.SIGN_INVOICE">
                      <app-invoice [quoteStateId]="quoteStateId" [group]="item" [canLoadContent]="answersSaved">
                      </app-invoice>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.CONGRATULATIONS">
                      <app-congratulations [quoteStateId]="quoteStateId" [group]="item" (stateReturn)="onGoBackInState($event)">
                      </app-congratulations>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.IMAGE_UPLOAD_QUESTION">
                      <app-image-input [save]="saveNotifier" [quoteStateId]="quoteStateId" [stateName]="stateName" [group]="item" [parameterName]="item.controls.name.value"
                        [label]="questionForm.value.items[i].question" [imageData]="questionForm.value.items[i].images" [questionForm]="questionForm"
                        [hasManualImageInput]="questionForm.value.items[i].hasManualImageInput"
                        (finalisedNotifier)="onAnswerChange($event)" [requiredImageType]="requiredImageType">
                      </app-image-input>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.REQUIRED_TO_BUY">
                      <app-required-to-buy [quoteStateId]="quoteStateId" [group]="item" [questionForm]="questionForm" [defaults]="questionDefaults" [vrm]="requestContext"></app-required-to-buy>
                    </ng-container>

                    <ng-container *ngSwitchCase="controlTypes.SIGN_D2">
                      <app-d2-form [quoteStateId]="quoteStateId" [group]="item" [canLoadContent]="answersSaved">
                      </app-d2-form>
                    </ng-container>

                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div class="card text-white bg-info mb-1" *ngIf="infoMessage && !hasNoDealErrors">
              <div class="card-body">
                {{ infoMessage }}
              </div>
            </div>
            <div class="card bg-warning mb-1" *ngIf="hasNoDealErrors">
              <div class="card-body">
                {{ noDealErrors[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-container *ngIf="isBusy && !isFinishedLoading">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>

<ng-container *ngIf="loadingFromChild">
  <app-loading-wbac-animation></app-loading-wbac-animation>
</ng-container>
