<div class="container-fluid main-page">
  <div class="top-nav-bar-container" [ngClass]="{'top-nav-bar-container--disabled': isNotInBranch}">
    <div class="top-nav-bar">
      <div class="calendar-btns">
        <a class="nav-button" (click)="setView(diaryView.Day)"
          [ngClass]="{ 'selected':view === diaryView.Day && !isNotInBranch, 'nav-button--disabled button-text--disabled': view === diaryView.Day && isNotInBranch}">
          <img *ngIf="!isNotInBranch" src="../../assets/icons/calendar/dayViewButton.png" alt='day-view-button'>
          <img *ngIf="isNotInBranch" src="../../assets/icons/calendar/calendar-icon-disabled.png"
            alt='calendar-icon-disabled'>
          <span class="button-text"
            [ngClass]="{ 'selected':view === diaryView.Day && !isNotInBranch, 'nav-button--disabled button-text--disabled': view === diaryView.Day && isNotInBranch }">Day</span>
        </a>
        <a class="nav-button" (click)="setView(diaryView.Week)"
          [ngClass]="{ 'selected':view === diaryView.Week && !isNotInBranch, 'nav-button--disabled button-text--disabled': view === diaryView.Day && isNotInBranch }">
          <img *ngIf="!isNotInBranch" src="../../assets/icons/calendar/weekViewButton.png" alt="week-view-button">
          <img *ngIf="isNotInBranch" src="../../assets/icons/calendar/calendar-icon-disabled.png"
            alt="calendar-icon-disabled">
          <span class="button-text"
            [ngClass]="{ 'selected':view === diaryView.Week && !isNotInBranch, 'nav-button--disabled button-text--disabled': view === diaryView.Day && isNotInBranch }">Week</span>
        </a>
        <a *ngIf="buyersCount > 1" class="nav-button" (click)="setView(diaryView.Branch)"
          [ngClass]="{ 'selected':view === diaryView.Branch && !isNotInBranch, 'nav-button--disabled button-text--disabled': view === diaryView.Day && isNotInBranch }">
          <img *ngIf="!isNotInBranch" src="../../assets/icons/buttons/Branch_Calendar.svg" alt="week-view-button">
          <img *ngIf="isNotInBranch" src="../../assets/icons/calendar/calendar-icon-disabled.png"
            alt="calendar-icon-disabled">
          <span class="button-text"
            [ngClass]="{ 'selected':view === diaryView.Branch && !isNotInBranch, 'nav-button--disabled button-text--disabled': view === diaryView.Day && isNotInBranch }">Branch</span>
        </a>
      </div>
      <div class="control-btns">

        <div
          [ngClass]="{'unsuccessfulSearch':  hasSuccessfulSearch === false,'popout control-btns container-border' : true === true}">
          <div class="input-group search-btn  ">
            <input type="text" id="vrm" name="vrm" class="vrm-search unsuccessfulSearch" placeholder="Search"
              maxlength="8" autocomplete="off" autocorrect="off" [(ngModel)]="vrm" />
          </div>
          <button class="btn btn-block modal-button search-btn" src="../../assets/icons/buttons/search.png"
            [ngClass]="{'loading': loading}" (click)="search()">
            <img class="search-image" src="../../assets/icons/buttons/search-disabled.png" alt="Search" />
          </button>
        </div>

        <div ngbDropdown class="dropdown popout">
          <button ngbDropdownToggle id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"
            class="popout-btn">
            <img *ngIf="!isNotInBranch" src="../../assets/icons/buttons/popout.png" alt='popout-button'>
            <img *ngIf="isNotInBranch" src="../../assets/icons/buttons/popout-button-disabled.png"
              alt='disabled-popout-button-menu'>
          </button>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a ngbDropdownItem class="dropdown-item popout-btn float-btn top-btn" (click)="walkIn(false)">
              <img src="../../assets/icons/buttons/popout-bookValuation.png" alt='popout-book-valuation'>
              <span class="float-btn-text">Book Valuation</span>
            </a>
            <a ngbDropdownItem class="dropdown-item popout-btn float-btn" (click)="quickQuote()">
              <img src="../../assets/icons/buttons/quickQuote-Inactive.png" alt='quick-quote-inactive'>
              <span class="float-btn-text">Quick Quote</span>
            </a>
            <a ngbDropdownItem class="dropdown-item walk-in-btn popout-btn float-btn" (click)="walkIn(true)">
              <img src="../../assets/icons/buttons/popout-purchaseVehicle.png" alt='poput-purchase-vehicle'>
              <span class="float-btn-text">Purchase Vehicle</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="diaryLoading" style="flex: auto;">
    <app-loading-wbac-animation></app-loading-wbac-animation>
  </div>

  <div [ngSwitch]="view" *ngIf="buyerWeeklySchedule">
    <app-day-view *ngSwitchCase="diaryView.Day" [dayHours]="dayHours" [currentDate]="currentDate" [isBranchView]="false"
      [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [defaultDisplayStartHour]="defaultDisplayStartHour"
      [buyerWeeklySchedule]="buyerWeeklySchedule" [currentTime]="currentTime">
    </app-day-view>
    <app-week-view *ngSwitchCase="diaryView.Week" [currentDate]="currentDate" [dayHours]="dayHours" [isBranchView]="false"
      [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [defaultDisplayStartHour]="defaultDisplayStartHour"
      [buyerWeeklySchedule]="buyerWeeklySchedule" [currentTime]="currentTime">
    </app-week-view>
    <app-day-view *ngSwitchCase="diaryView.Branch" [currentDate]="currentDate" [isBranchView]="true"  [dayHours]="dayHours"
    [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [defaultDisplayStartHour]="defaultDisplayStartHour"
    [buyerWeeklySchedule]="buyerWeeklySchedule" [currentTime]="currentTime">
    </app-day-view>
  </div>
</div>
